"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fancyBoxInit = void 0;
const ui_1 = require("@fancyapps/ui");
function fancyBoxInit() {
    const fancyItems = document.querySelectorAll('a[data-fancybox]').length > 0;
    if (fancyItems) {
        ui_1.Fancybox.bind("[data-fancybox]", {});
    }
}
exports.fancyBoxInit = fancyBoxInit;
